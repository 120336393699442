const Storage = window.localStorage;

/**
 * Add a key value in the local storage
 * */
function set(key, value) {
  if (Storage) {
    Storage.setItem(key, JSON.stringify(value));
  }
}

/**
 * Remove key from local storage
 */
function remove(key) {
  if (Storage) {
    Storage.removeItem(key);
  }
}

/**
 * Get an item from local storage by key
 *
 * @param {*} key
 * @returns
 */
function get(key) {
  if (!Storage) return null;
  let value = Storage.getItem(key);
  if (!value) return null;
  value = value.replace('__q_strn|', '');
  return JSON.parse(value);
}

export default {
  get,
  set,
  remove,
};
