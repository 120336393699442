import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.locale('pt-br');
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

dayjs.tz.setDefault('UTC');

export function todayUTC() {
  return dayjs().utc().format('DD/MM/YYYY');
}

export function dateUTC(date) {
  date = dayjs.isDayjs(date) ? date : dayjs.utc(date);
  return date.utc().format('DD/MM/YYYY');
}

export function fullDateUTC(date) {
  date = dayjs.isDayjs(date) ? date : dayjs.utc(date);
  return date.utc().format('LL');
}

export function fullDateTimeUTC(date) {
  date = dayjs.isDayjs(date) ? date : dayjs.utc(date);
  return date.utc().format('LL [às] LTS');
}

export function fullDateTime(date) {
  date = dayjs.isDayjs(date) ? date : dayjs.utc(date);
  return date.tz('America/Sao_Paulo').format('DD/MM/YYYY [às] LTS');
}

export function yearMonth() {
  return dayjs().tz('America/Sao_Paulo').format('YYYY-MM');
}

export function convertTimestampToDate(date) {
  date = dayjs.isDayjs(date) ? date : dayjs.utc(date);
  return date.utc().format('DD/MM/YYYY');
}

export function convertDateToTimestamp(date) {
  date = dayjs.isDayjs(date) ? date : dayjs(date, 'DD/MM/YYYY');
  return date.utc().format('YYYY-MM-DD');
}

export function diff(date1, date2) {
  date1 = dayjs.isDayjs(date1) ? date1 : dayjs(date1);
  date2 = dayjs.isDayjs(date2) ? date2 : dayjs(date2);
  return date1.diff(date2);
}

export default dayjs;
