import api from '@/services/api';
import { setLoadingStatus, setItem } from '../mutations';
import { item } from '../getters';

const actions = {
  async bookSpecies({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/board-of-trade/book-species', { params });
      commit('setBookSpecies', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível carregar a lista de livros.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async bookPurposes({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/board-of-trade/book-purposes', { params });
      commit('setBookPurposes', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível carregar a lista de livros.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async signerFunctions({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/board-of-trade/signer-functions', { params });
      commit('setSignerFunctions', data);
      return { data };
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível carregar a lista de livros.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async returnExamBookResult({ commit, rootState }, { id, protocol }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
      protocol,
    };
    try {
      const { data } = await api.get(`v1/board-of-trade/${id}/return-exam-book-result`, { params });
      commit('setItem', data);
      return { data };
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível carregar a lista de livros.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async generateDigitalBook({ commit, rootState }, { id, body }) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post(`v1/board-of-trade/${id}/generate-digital-book`, body);
      commit('setItem', data);
      return { data };
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível enviar o livro para a Junta Comercial.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setItem,
  setBookSpecies(st, bookSpecies) {
    st.bookSpecies = bookSpecies;
  },
  setBookPurposes(st, bookPurposes) {
    st.bookPurposes = bookPurposes;
  },
  setSignerFunctions(st, signerFunctions) {
    st.signerFunctions = signerFunctions;
  },
};

const getters = {
  item,
  bookSpecies(st) {
    return st.bookSpecies;
  },
  bookPurposes(st) {
    return st.bookPurposes;
  },
  signerFunctions(st) {
    return st.signerFunctions;
  },
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    item: {},
    bookSpecies: [],
    bookPurposes: [],
    signerFunctions: [],
  },
  getters,
  mutations,
  actions,
};
