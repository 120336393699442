/**
 * Retorna os itens
 * @export
 * @param {Object} state
 * @returns
 */
export function items(state) {
  return state.items;
}

/**
 * Retorna o item selecionado
 * @export
 * @param {Object} state
 * @returns
 */
export function item(state) {
  return state.item;
}

export function getDocument(state) {
  return state.documentBase64;
}
