import axios from '@/plugins/axios';
import errorHandler from '@/utils/validation-handler';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};

export { axios };

const api = {
  async get(url, config = {}) {
    try {
      return await axios.get(url, config);
    } catch (error) {
      return errorHandler(error);
    }
  },

  async post(url, data = {}, config = {}) {
    try {
      return await axios.post(url, data, config);
    } catch (error) {
      return errorHandler(error);
    }
  },

  async patch(url, data = {}, config = {}) {
    try {
      return await axios.patch(url, data, config);
    } catch (error) {
      return errorHandler(error);
    }
  },

  async delete(url, config = {}) {
    try {
      return await axios.delete(url, config);
    } catch (error) {
      return errorHandler(error);
    }
  },
};

export default api;
