import Vue from 'vue';
import auth from '@websanova/vue-auth/src/v2';
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x';
import LogRocket from 'logrocket';

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    tokenDefaultKey: 'digital_auth_token',
    stores: ['storage'],
    rolesKey: 'main_role',
    refreshData: {
      url: 'v1/auth/refresh',
    },
    fetchData: {
      url: 'v1/auth/user',
    },
    loginData: {
      url: 'v1/auth/login',
      redirect: false,
      fetchUser: false,
    },
    logoutData: {
      url: 'v1/auth/logout',
      method: 'DELETE',
      redirect: { name: 'login' },
      makeRequest: true,
    },
    notFoundRedirect: { name: 'not-found' },
    registerData: {
      redirect: { name: 'dashboard' },
      autoLogin: true,
    },
    parseUserData(data) {
      if (process.env.NODE_ENV === 'production') {
        LogRocket.init('societario-digital/app-web');
        LogRocket.identify(data.id, {
          name: data.name,
          email: data.email,
        });
      }
      return data || {};
    },
  },
});
