import api from '@/services/api';
import { setLoadingStatus, setCollection, addItem } from '../mutations';
import { items } from '../getters';

const actions = {
  async index({ commit, rootGetters }, params = {}) {
    commit('setLoadingStatus', true);
    params.contract_ids = rootGetters['auth/user'].contract_ids;
    try {
      const { data } = await api.get('v1/users', { params });
      commit('setCollection', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível carregar os usuários.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create({ commit, rootGetters }, body) {
    commit('setLoadingStatus', true);
    body.contract_ids = rootGetters['auth/user'].contract_ids;
    try {
      const { data } = await api.post('v1/users', body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível salvar os dados.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootGetters }, body) {
    commit('setLoadingStatus', true);
    body.contract_ids = rootGetters['auth/user'].contract_ids;
    try {
      const { data } = await api.patch(`v1/users/${body.id}`, body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível editar os dados.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async remove({ commit, dispatch, rootGetters }, id) {
    commit('setLoadingStatus', true);
    const params = {
      contract_ids: rootGetters['auth/user'].contract_ids,
    };
    try {
      await api.delete(`v1/users/${id}`, { params });
      await dispatch('index');
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível deletar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  addItem,
};

const getters = {
  items,
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: {
    isLoading: false,
    items: [],
  },
};
