import Vue from 'vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
  duration: 5000,
  containerClass: 'v-application',
  position: 'top-center',
  action: {
    icon: 'X',
    class: 'white--text',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
});
