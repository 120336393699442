import Vue from 'vue';
import api from '@/services/api';

const actions = {
  async login(_, data = {}) {
    const response = await Vue.auth.login({
      data: {
        email: data.email,
        password: data.password,
        otp: data.otp,
      },
      remember: data.remember ? data.remember : false,
      staySignedIn: data.staySignedIn ? data.staySignedIn : true,
    });
    if (data.remember) {
      Vue.auth.remember(JSON.stringify({ email: data.email }));
    }
    return response;
  },

  async register(ctx, data) {
    await Vue.auth.register({ data: data.user, autoLogin: true });
    data.remember = true;
    data.staySignedIn = true;
    if (data.autoLogin) this.login(ctx, data);
  },

  logout({ dispatch }) {
    Vue.auth.logout();
    dispatch('company/resetCompany', {}, { root: true });
  },

  async reset(_, email) {
    try {
      await api.post('v1/auth/reset', { email });
    } catch {
      throw new Error('E-mail não encontrado.');
    }
  },

  async change(_, passwords) {
    try {
      await api.post('v1/auth/change', passwords);
    } catch (error) {
      if (error.message.includes('E_PASSWORD_MISMATCH')) throw new Error('Senha atual incorreta.');
      throw new Error(error.message);
    }
  },

  async recover(_, { token, password }) {
    try {
      await api.post(
        'v1/auth/recover',
        { password },
        {
          params: { token },
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );
    } catch {
      throw new Error('Não foi possível atualizar a senha.');
    }
  },

  async generateOTP() {
    try {
      const { data } = await api.post('v1/auth/generateOTP');
      return data;
    } catch (error) {
      throw new Error('Não foi possível gerar o segredo.');
    }
  },

  async validateOTP(_, otp) {
    try {
      const { data } = await api.post('v1/auth/validateOTP', otp);
      return data;
    } catch (error) {
      throw new Error('Não foi possível validar o token.');
    }
  },

  async disableOTP() {
    try {
      const { data } = await api.post('v1/auth/disableOTP');
      return data;
    } catch (error) {
      throw new Error('Não foi possível desabilitar a autenticação.');
    }
  },
};

export default {
  namespaced: true,
  state: {},
  mutations: {
    setUser: (state, user) => {
      state.user = user;
    },
  },
  actions,
  getters: {
    user: () => Vue.auth.user(),
    token: () => Vue.auth.token(),
    check: () => (role) => Vue.auth.check(role, Vue.auth.options.rolesKey),
  },
};
