import api from '@/services/api';
import {
  setLoadingStatus, setCollection, addItem, setItem,
} from '../mutations';
import { item } from '../getters';

const actions = {
  async index({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/books/sa/debenture-registry', { params });
      commit('setCollection', data);
    } catch (error) {
      throw new Error('Não foi possível carregar a lista de debêntures.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async getSignerResume({ commit, rootState }, { id, bookId }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
      book_id: bookId,
    };
    try {
      const { data } = await api.get(`v1/books/sa/debenture-registry/resume/${id}`, { params });
      commit('setSignerResume', data);
    } catch (error) {
      throw new Error('Não foi possível carregar a lista de debêntures.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/books/sa/debenture-registry', body);
      commit('addItem', data);
      return data;
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível salvar os dados da debênture.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.patch(`v1/books/sa/debenture-registry/${body.id}`, body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível editar os dados da debênture.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async remove({ commit, rootState }, { id }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      await api.delete(`v1/books/sa/debenture-registry/${id}`, { params });
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível deletar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  addItem,
  setItem,
  setAction(st, action) {
    st.action = action;
  },
  setSignerResume(st, signerResume) {
    st.signerResume = signerResume;
  },
};

const getters = {
  items(st) {
    return st.items
      .filter((debenture) => debenture.action === st.action)
      .map((debenture) => ({
        ...debenture,
        conver_convertidas: Math.abs(debenture.conver_convertidas),
        trans_transferidas: Math.abs(debenture.trans_transferidas),
        trans_canceladas: Math.abs(debenture.trans_canceladas),
        averb_transmissao: Math.abs(debenture.averb_transmissao),
        averb_livres: Math.abs(debenture.averb_livres),
      }));
  },
  item,
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    items: [],
    item: {},
    action: null,
    signerResume: [],
  },
  getters,
  mutations,
  actions,
};
