import api from '@/services/api';
import isExpiringSoon from '@/utils/is-expiring-soon';
import {
  setLoadingStatus, setCollection, addItem, setItem, setCount,
} from '../mutations';
import { items, item } from '../getters';

const actions = {
  async index({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/books', { params });
      commit('setCollection', data);
      commit('sortBy');
    } catch (error) {
      throw new Error('Não foi possível carregar a lista de livros.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async count({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/books/count', { params });
      commit('setCount', data);
      return data;
    } catch (error) {
      throw new Error('Não foi possível contar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async show({ commit, rootState }, id) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      const { data } = await api.get(`v1/books/${id}`, { params });
      commit('setItem', data);
    } catch (error) {
      throw new Error('Não foi possível carregar o livro.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async preview({ commit, rootState }, {
    id, official, assembleOnly, orderby, isPreview,
  }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
      official,
      assembleOnly,
      orderby,
      isPreview,
    };
    try {
      const { data, headers } = await api.get(`v1/books/${id}/preview`, {
        params,
        responseType: 'blob',
      });
      return { data, filename: headers.filename };
    } catch (error) {
      throw new Error('Não foi possível fazer o download do livro.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async download({ commit, rootState }, { id, folder, filename }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
      folder,
      filename,
    };
    try {
      const { data, headers } = await api.get(`v2/books/${id}/download`, {
        params,
        responseType: 'blob',
      });
      return { data, filename: headers.filename };
    } catch (error) {
      throw new Error('Não foi possível fazer o download do livro.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async convert({ commit, rootState }, { id, orderby }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
      orderby,
    };
    try {
      const { data } = await api.get(`v1/books/${id}/convert`, {
        params,
      });
      return { data };
    } catch (error) {
      throw new Error('Não foi possível fazer o download do livro.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async exists({ commit, rootState }, { folder, filename }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
      folder,
      filename,
    };
    try {
      const { data } = await api.get('v1/books/exists', {
        params,
      });
      commit('setExists', data);
      return { data };
    } catch (error) {
      throw new Error('Não foi possível fazer o download do livro.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/books', body);
      commit('addItem', data);
      return data;
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível salvar os dados do livro.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.patch(`v1/books/${body.id}`, body);
      commit('addItem', data);
      commit('setItem', data);
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível editar os dados do livro.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async open({ commit, rootState }, bookId) {
    commit('setLoadingStatus', true);
    const body = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      const { data } = await api.patch(`v1/books/${bookId}/open`, body);
      commit('addItem', data);
      commit('setItem', data);
    } catch (error) {
      if (error.response && error.response.status === 403) throw new Error(error.response.data);
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível reabrir o documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async remove({ commit, dispatch, rootState }, id) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      await api.delete(`v1/books/${id}`, { params });
      await dispatch('index');
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível deletar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  addItem,
  setItem,
  setExists(st, exists) {
    st.exists = exists;
  },
  setCount,
  sortBy(st) {
    st.items = st.items.sort((a, b) => {
      const aExpiring = isExpiringSoon(a);
      const bExpiring = isExpiringSoon(b);

      if (aExpiring && !bExpiring) return -1;
      if (!aExpiring && bExpiring) return 1;

      return a.title.localeCompare(b.title);
    });
  },
};

const getters = {
  items,
  item,
  exists(st) {
    return st.exists;
  },
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    exists: false,
    items: [],
    item: {},
    count: 0,
  },
  getters,
  mutations,
  actions,
};
