import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';
import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';

import auth from './modules/auth/index';
import company from './modules/company/index';
import signer from './modules/signer/index';
import book from './modules/book/index';
import bookSAStockRegistry from './modules/book-sa-stock-registry/index';
import bookSADebentureRegistry from './modules/book-sa-debenture-registry/index';
import document from './modules/document/index';
import contract from './modules/contract/index';
import stock from './modules/stock/index';
import debenture from './modules/debenture/index';
import user from './modules/user/index';
import log from './modules/log/index';
import folder from './modules/folder/index';
import boardOfTrade from './modules/board-of-trade/index';
import companyQuota from './modules/company-quota/index';

const debug =
  process.env.NODE_ENV !== 'production' && process.env.VUE_APP_DEBUG.toLowerCase() === 'true';
const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

Vue.store = new Vuex.Store({
  strict: debug,
  plugins: debug ? [createLogger()] : [logrocketPlugin],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    company,
    signer,
    book,
    bookSAStockRegistry,
    bookSADebentureRegistry,
    document,
    stock,
    debenture,
    contract,
    user,
    log,
    folder,
    boardOfTrade,
    companyQuota,
  },
});

export default Vue.store;
