import api from '@/services/api';
import {
  setLoadingStatus, setCollection, addItem, setItem,
} from '../mutations';
import { item } from '../getters';

const actions = {
  async index({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/books/sa/stock-registry', { params });
      commit('setCollection', data);
    } catch (error) {
      throw new Error('Não foi possível carregar a lista de ações.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async getSignerResume({ commit, rootState }, { id, bookId }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
      book_id: bookId,
    };
    try {
      const { data } = await api.get(`v1/books/sa/stock-registry/resume/${id}`, { params });
      commit('setSignerResume', data);
    } catch (error) {
      throw new Error('Não foi possível carregar a lista de ações.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/books/sa/stock-registry', body);
      commit('addItem', data);
      return data;
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível salvar os dados da ação.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.patch(`v1/books/sa/stock-registry/${body.id}`, body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível editar os dados da ação.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async remove({ commit, rootState }, { id }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      await api.delete(`v1/books/sa/stock-registry/${id}`, { params });
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível deletar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  addItem,
  setItem,
  setAction(st, action) {
    st.action = action;
  },
  setSignerResume(st, signerResume) {
    st.signerResume = signerResume;
  },
};

// Agrupa as ações por id e calcula o valor total de cada, retorna array de array
function getArrayStocksOnlyInfo(stocksUniqueTypes, arrayStocks) {
  const resultArrays = [];
  stocksUniqueTypes.forEach((stockUnique) => {
    const filteredStocks = arrayStocks.reduce((arr, stock) => {
      // verifica se tem uma conversão, para somar no tipo de ação que foi convertida para
      if (stock.conver_stock_id) {
        if (
          stock.converStock.type === stockUnique.type &&
          stock.stock.class === stockUnique.class
        ) {
          const total = stock.conver_quantidade;
          arr.push({
            stockId: stock.converStock.id,
            signerId: stock.signer_id,
            name: stock.signer.type === 'PF' ? stock.signer.name : stock.signer.business_name,
            type: stock.converStock.type,
            class: stock.converStock.class,
            id: stock.id,
            value: total * stockUnique.value,
            issuePrice: total * stockUnique.issue_price,
            total,
          });
        }
      }
      if (stock.stock.type === stockUnique.type && stock.stock.class === stockUnique.class) {
        const total =
          stock.subsc_subscritas +
          stock.trans_adquiridas +
          stock.trans_transferidas +
          stock.amort_quantidade +
          stock.conver_convertidas; // soma a conversão negativa
        arr.push({
          stockId: stock.stock_id,
          signerId: stock.signer_id,
          name: stock.signer.type === 'PF' ? stock.signer.name : stock.signer.business_name,
          type: stock.stock.type,
          class: stock.stock.class,
          id: stock.id,
          value: total * stockUnique.value, // valor da ação correspondente a data de pesquisa
          issuePrice: total * stockUnique.issue_price, // preço correspondente a data de pesquisa
          total,
        });
      }
      return arr;
    }, []);
    resultArrays.push(filteredStocks);
  });
  return resultArrays;
}
// Agrupa por acionista dentro de cada array, devolve array de array
function getArrayStockBySigner(arrays) {
  const finalResultArray = [];
  arrays.forEach((array) => {
    const result = {};
    array.forEach((stock) => {
      const {
        signerId, total, value, stockId, name, type, issuePrice,
      } = stock;
      if (!result[signerId]) {
        result[signerId] = stock;
      } else {
        result[signerId].signerId = signerId;
        result[signerId].name = name;
        result[signerId].type = type;
        result[signerId].class = stock.class;
        result[signerId].stock_id = stockId;
        result[signerId].total += total;
        result[signerId].value += value;
        result[signerId].issuePrice += issuePrice;
      }
    });
    finalResultArray.push(Object.values(result));
  });
  return finalResultArray;
}
// calcula a particiação de cada acionista, devolve array de array
function getArrayStockWithParticipation(arrays, arrayStocksRaw) {
  const stockToCalc = arrayStocksRaw.reduce((acc, stock) => acc + stock.total, 0);
  arrays.forEach((array) => {
    array.forEach((stock) => {
      stock.part = Number((stock.total / stockToCalc) * 100);
    });
  });
  return arrays;
}
// devolve o array espalhado de captable, o valor e a participação total do montante de acionistas
function calculateTotal(array) {
  let resultArray = [];
  // esse array retorna com mais de uma especie e classe de ação por assinante
  // então agrupa apenas por UMA especie
  array.forEach((arr) => {
    arr.forEach((sig) => {
      const {
        signerId, total, value, stockId, name, type, issuePrice, part,
      } = sig;
      if (!resultArray[signerId + type]) {
        resultArray[signerId + type] = sig;
      } else {
        resultArray[signerId + type].signerId = signerId;
        resultArray[signerId + type].name = name;
        resultArray[signerId + type].type = type;
        resultArray[signerId + type].stock_id = stockId;
        resultArray[signerId + type].total += total;
        resultArray[signerId + type].value += value;
        resultArray[signerId + type].issuePrice += issuePrice;
        resultArray[signerId + type].part += part;
      }
    });
  });
  resultArray = Object.values(resultArray);
  const totalQtd = resultArray.reduce((acc, stock) => acc + stock.total, 0);
  const totalPrice = resultArray.reduce((acc, stock) => acc + stock.value, 0);
  const totalIssuePrice = resultArray.reduce((acc, stock) => acc + stock.issuePrice, 0);
  const totalPart = resultArray.reduce((acc, stock) => acc + stock.part, 0).toFixed(2);
  resultArray = resultArray.filter((cap) => (cap.total > 0 ? cap : 0));
  const total = {
    name: 'Total',
    type: ' ',
    total: totalQtd,
    value: totalPrice,
    issuePrice: totalIssuePrice,
    part: totalPart,
  };
  const captableExcelExport = resultArray.map((cap) => ({
    ...cap,
    part: cap.part.toString().replace('.', ','),
  }));
  return {
    captable: [...resultArray, total],
    captableExcelExport,
    totalQtd,
    totalPrice,
    totalPart,
  };
}

const getters = {
  items(st) {
    return st.items;
  },
  itemsPositive(st) {
    return st.items.map((stock) => ({
      ...stock,
      conver_convertidas: Math.abs(stock.conver_convertidas),
      trans_transferidas: Math.abs(stock.trans_transferidas),
      amort_quantidade: Math.abs(stock.amort_quantidade),
    }));
  },
  itemsByAction(st) {
    return st.items
      .filter((stock) => stock.action === st.action)
      .map((stock) => ({
        ...stock,
        conver_convertidas: Math.abs(stock.conver_convertidas),
        trans_transferidas: Math.abs(stock.trans_transferidas),
        amort_quantidade: Math.abs(stock.amort_quantidade),
      }));
  },
  getCaptable(state, get, rootState, rootGetters) {
    const arrayStocksOnlyInfo = getArrayStocksOnlyInfo(rootGetters['stock/items'], get.items);
    const arrayStockBySigner = getArrayStockBySigner(arrayStocksOnlyInfo);
    const arrayComplete = getArrayStockWithParticipation(
      arrayStockBySigner,
      rootGetters['stock/items'],
    );
    return calculateTotal(arrayComplete);
  },
  item,
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    items: [],
    item: {},
    action: null,
    signerResume: [],
  },
  getters,
  mutations,
  actions,
};
