import api from '@/services/api';
import { setLoadingStatus, setCollection, addItem } from '../mutations';
import { items } from '../getters';

const actions = {
  async index({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/debentures', { params });
      commit('setCollection', data);
    } catch (error) {
      throw new Error('Não foi possível carregar as ações.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/debentures', body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível salvar os dados.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.patch(`v1/debentures/${body.id}`, body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      throw new Error('Não foi possível editar os dados.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  addItem,
};

const getters = {
  items,
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    items: [],
  },
  getters,
  mutations,
  actions,
};
