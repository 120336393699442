<template>
  <v-app>
    <v-fade-transition hide-on-leave class="fill-height">
      <router-view class="fill-height" />
    </v-fade-transition>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  created() {
    console.log('Inicializando', process.env.NODE_ENV);
  },
};
</script>
<style>
.fill-height {
  height: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}
</style>
