import BadRequestException from '@/exceptions/bad-request';
import UnauthorizedException from '@/exceptions/unauthorized';
import ValidationException from '@/exceptions/validation';

export default function errorHandler(error) {
  if (error.response && error.response.status === 400) {
    const { message } = error.response.data;
    throw new BadRequestException(message);
  }

  if (error.response && error.response.status === 401) {
    const { message, errors } = error.response.data;
    throw new UnauthorizedException(message, errors);
  }

  if (error.response && error.response.status === 422) {
    const { message, errors } = error.response.data;
    throw new ValidationException(message, errors);
  }

  throw error;
}
