import api from '@/services/api';
import storage from '@/services/storage';
import { setLoadingStatus, setCollection, addItem } from '../mutations';
import { items } from '../getters';

const actions = {
  selectCompany({ commit, dispatch, rootGetters }, company) {
    commit('selectCompany', company);
    commit('contract/setCollection', {}, { root: true });
    storage.set('digital_company', company);
    dispatch('book/index', {}, { root: true });
    dispatch('book/count', {}, { root: true });
    dispatch('stock/index', {}, { root: true });
    dispatch('debenture/index', {}, { root: true });
    if (rootGetters['auth/check']('administrator') || rootGetters['auth/check']('editor')) {
      dispatch('signer/index', {}, { root: true });
      dispatch('signer/count', {}, { root: true });
      dispatch('contract/resume', {}, { root: true });
      dispatch('log/index', { limit: 10 }, { root: true });
    }
  },
  reSignCompany({ commit }, company) {
    commit('selectCompany', company);
    storage.set('digital_company', company);
  },
  resetCompany({ commit }) {
    commit('selectCompany', null);
    storage.remove('digital_company');
  },
  async index({ commit, rootGetters }, params = {}) {
    commit('setLoadingStatus', true);
    params.contract_ids = rootGetters['auth/user'].contract_ids;
    try {
      const { data } = await api.get('v1/companies', { params });
      commit('setCollection', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível carregar as empresas.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create({ commit, rootGetters }, body) {
    commit('setLoadingStatus', true);
    body.contract_ids = rootGetters['auth/user'].contract_ids;
    try {
      const { data } = await api.post('v1/companies', body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível salvar os dados.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootGetters }, body) {
    commit('setLoadingStatus', true);
    body.contract_ids = rootGetters['auth/user'].contract_ids;
    try {
      const { data } = await api.patch(`v1/companies/${body.id}`, body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      if (error.name === 'ValidationException') throw error;
      throw new Error('Não foi possível editar os dados.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  selectCompany(state, company) {
    state.selectedCompany = company;
  },
  setLoadingStatus,
  setCollection,
  addItem,
};

const getters = {
  isSA(st) {
    return st.selectedCompany && st.selectedCompany.type === 'SA';
  },
  isLTDA(st) {
    return st.selectedCompany && st.selectedCompany.type === 'LTDA';
  },
  getCompanyName(st) {
    return st.selectedCompany.name;
  },
  items,
  itemsActive(st) {
    return st.items.filter((item) => item.active);
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: {
    isLoading: false,
    selectedCompany: storage.get('digital_company'),
    items: [],
  },
};
