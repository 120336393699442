import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import VueAxios from 'vue-axios';

const instance = axios.create({
  timeout: 60000, // 1 minuto
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response
      && [401, 419].includes(error.response.status)
      && Vue.store.getters['auth/user']
    ) {
      Vue.store.dispatch('company/resetCompany', {}, { root: true });
      alert('Sessão inválida/expirada. Faça o login novamente.');
      window.location.replace('/login');
    }

    if (error.response && [404].includes(error.response.status)) {
      Vue.router.push({ name: 'not-found' });
    }

    return Promise.reject(error);
  },
);

Vue.use(VueAxios, instance);

export default instance;
