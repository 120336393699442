import api from '@/services/api';
import { setLoadingStatus, setCollection, addItem } from '../mutations';
import { items } from '../getters';

const actions = {
  async resume({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/contracts/resume', { params });
      commit('setCollection', data);
    } catch (error) {
      console.error('Não foi possível carregar o resumo do contrato.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  addItem,
};

const getters = {
  items,
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    items: {},
  },
  getters,
  mutations,
  actions,
};
