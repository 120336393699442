import api from '@/services/api';
import {
  setLoadingStatus, setCollection, addItem, setItem, setCount,
} from '../mutations';
import { items, item } from '../getters';

const actions = {
  async index({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/folders', { params });
      commit('setCollection', data);
    } catch (error) {
      throw new Error('Não foi possível carregar a lista de pastas.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async count({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/folders/count', { params });
      commit('setCount', data);
      return data;
    } catch (error) {
      throw new Error('Não foi possível contar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async show({ commit, rootState }, id) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      const { data } = await api.get(`v1/folders/${id}`, { params });
      commit('setItem', data);
    } catch (error) {
      throw new Error('Não foi possível carregar a pasta.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/folders', body);
      commit('addItem', data);
      return data;
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível salvar os dados da pasta.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async createDocument(
    { commit, rootState },
    {
      body,
      folderId,
      name,
      type,
      official,
      sign,
    },
  ) {
    commit('setLoadingStatus', true);
    const companyId = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post(`v1/folders/${folderId}/document`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          company_id: companyId,
          folder_id: folderId,
          name,
          type,
          official,
          sign,
        },
      });
      return data;
    } catch (error) {
      if (error.response && error.response.status === 403) throw new Error(error.response.data);
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível criar o documento.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.patch(`v1/folders/${body.id}`, body);
      commit('addItem', data);
      commit('setItem', data);
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível editar os dados da pasta.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async remove({ commit, dispatch, rootState }, id) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      await api.delete(`v1/folders/${id}`, { params });
      await dispatch('index');
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível deletar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  addItem,
  setItem,
  setExists(st, exists) {
    st.exists = exists;
  },
  setCount,
};

const getters = {
  items,
  item,
  exists(st) {
    return st.exists;
  },
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    exists: false,
    items: [],
    item: {},
    count: 0,
  },
  getters,
  mutations,
  actions,
};
